/* eslint-disable react/no-unknown-property */
import React from "react"
import { css } from "@emotion/react"

import { getColorScheme } from "common/colorScheme"
import styled from "@emotion/styled"
import Media from "components/Media"
import { H2, H4, P } from "../headings"
import Card from "./Card/Card"
import Container from "../container"
import { getSizeStyles, useTitleSize } from "./TitleSizeContext"
import { ISectionProps, MediaSize } from "./section.model"
import { useSectionTheme } from "./SectionThemeContext"
import Button, { ButtonBase } from "../button"
import { mediaBreakpoint, BreakPoints } from "../../settings/breakpoints"
import { elementData, formatMultilineText } from "./utils"

const containerStyles = css`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 36px;
	${mediaBreakpoint(BreakPoints.SM)} {
		flex-direction: row;
		padding: 80px 16px;
	}
`

export const cardsTitleStyles = css`
	font-size: 3.2rem;
	line-height: 4.2rem;
	margin-top: 40px;

	${mediaBreakpoint(BreakPoints.MD)} {
		margin-top: 0;
	}
`

const textContainerStyles = css`
	flex: 1;
	display: flex;
	flex-direction: column;
`
export const cardsContainerStles = css`
	flex: 1;
	margin: -30px 0;
`

const buttonsContainerStyles = css`
	${ButtonBase} {
		width: 100%;
		margin: 0 0 12px;
		${mediaBreakpoint(BreakPoints.SM)} {
			width: initial;
			margin: 0;
			min-width: 134px;
		}
	}
`

const buttonStyles = css`
	margin: 1.6rem 0;
	${mediaBreakpoint(BreakPoints.SM)} {
		display: inline-block;
	}
`
const buttonsDesktop = css`
	display: none;
	${mediaBreakpoint(BreakPoints.SM)} {
		display: block;
	}
`
const buttonsMobile = css`
	display: block;
	${mediaBreakpoint(BreakPoints.SM)} {
		display: none;
	}
`

const CardsContainerHorizontal = styled.div`		
`

const MediaCardsRightContainer = styled.div`
	max-width: 200px;
	padding-bottom: 6rem;
	 img {
		 height: 43px;
	 }
	 ${mediaBreakpoint(BreakPoints.SM)} {		
		max-width: 400px;		
	}
`

const MediaContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	flex-basis: 100%;
	max-width: 100%;
	${mediaBreakpoint(BreakPoints.SM)} {
		flex-basis: 54%;
		max-width: 54%;
		margin: 0;
	}
`
const MediaWrapper = styled.div`
	max-width: 100%;
	width: auto;
`
const getMediaSize = (size: MediaSize) => {
	switch (size) {
	case MediaSize.Small:
		return css`
				width: 350px;
			`
	case MediaSize.Medium:
		return css`
				width: 500px;
			`
	case MediaSize.Full:
		return css`
				${mediaBreakpoint(BreakPoints.SM)} {
					margin: -60px 0;
				}
			`
	default:
		return css``
	}
}

const CardsRight = ({
	title,
	titleLevel,
	description,
	buttons,
	cards,
	media,
	mediaSize,
	cardsTitle,
	colorScheme,
	customStyle,
	cardsHorizontal,
	titleCardsRight,
	mediaCardsRight,
	cardsDescription,
	additionalText
}: ISectionProps) => {
	const titleSize = useTitleSize()
	const theme = useSectionTheme()

	return (
		<div css={customStyle}>
			<section css={getColorScheme(colorScheme)} data-element={elementData.cover}>
				{ cardsHorizontal ? 
					<Container css={containerStyles} data-element={elementData.container}>
						<div css={textContainerStyles}>
							<H2 css={getSizeStyles(titleSize)} sectionTheme={theme} as={titleLevel || "h2"}>
								{formatMultilineText(title)}
							</H2>
							{description && description.length && <P sectionTheme={theme}>{formatMultilineText(description)}</P>}
							{buttons.length
								? buttons.map(button => {
									const { id } = button
									return (
										<div key={id} css={[buttonsContainerStyles, buttonsDesktop]}>
											<Button css={buttonStyles} {...button} />
										</div>
									)
							  })
								: null}
						</div>
						<CardsContainerHorizontal data-element='cards-horizontal'>
							{ titleCardsRight ? <H4 data-element="cards-title-right">{titleCardsRight}</H4>: null}
							{ mediaCardsRight ? <MediaCardsRightContainer><Media {...mediaCardsRight} /></MediaCardsRightContainer> : null}
							{cardsTitle && <H4 css={cardsTitleStyles} data-element="cards-title">{cardsTitle}</H4>}
							<div css={cardsContainerStles} data-element="cards-container">
								{cards.length ? (cards || []).map((card, index) => <Card key={`card${index + 1}`} {...card} />) : null}
							</div>
						</CardsContainerHorizontal>
						{buttons.length
							? (buttons || []).map(button => {
								const { id } = button
								return (
									<div key={id} css={[buttonsContainerStyles, buttonsMobile]}>
										<Button css={buttonStyles} {...button} />
									</div>
								)
						  })
							: null}
					</Container>
					:
					<Container css={containerStyles} data-element={elementData.container}>
						<div css={textContainerStyles}>
							<H2 css={getSizeStyles(titleSize)} sectionTheme={theme} as={titleLevel || "h2"} >
								{formatMultilineText(title)}
							</H2>
							{description && description.length && <P sectionTheme={theme}>{formatMultilineText(description)}</P>}
							{additionalText && (
								<H2
									as={titleLevel || "h2"} 
									data-element="additional-text"
									css={getSizeStyles(titleSize)}
									sectionTheme={theme}
									dangerouslySetInnerHTML={{ __html: additionalText?.childMarkdownRemark.html }}
								/>
							)}
							<MediaContainer data-element={elementData.bannerMediaContainer}>
								{media ? (
									<MediaWrapper css={getMediaSize(mediaSize)} data-element={elementData.bannerMedia}>
										<Media
											imgStyle={{
												objectFit: "contain",
											}}
											{...media}
										/>
									</MediaWrapper>
								) : null}
							</MediaContainer>
							{buttons.length
								? buttons.map(button => {
									const { id } = button
									return (
										<div key={id} css={[buttonsContainerStyles, buttonsDesktop]}>
											<Button css={buttonStyles} {...button} />
										</div>
									)
							  })
								: null}
						</div>
						<div css={cardsContainerStles} data-element="cards-container">
							{cardsTitle && <H4 css={cardsTitleStyles}>{cardsTitle}</H4>}
							{cardsDescription ? <P> {cardsDescription} </P> : null }
							{cards.length ? (cards || []).map((card, index) => <Card key={`card${index + 1}`} {...card} />) : null}
						</div>
						{buttons.length
							? (buttons || []).map(button => {
								const { id } = button
								return (
									<div key={id} css={[buttonsContainerStyles, buttonsMobile]}>
										<Button css={buttonStyles} {...button} />
									</div>
								)
						  })
							: null}
					</Container>
				}
			</section>
		</div>
	)
}

export default React.memo(CardsRight)
